import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "counter"]

  connect() {
    this.updateCount()
  }

  updateCount() {
    const currentLength = this.inputTarget.value.length
    const maxLength = this.inputTarget.maxLength
    this.counterTarget.textContent = `${currentLength}/${maxLength} characters`

    // Optional: Add visual feedback when approaching/reaching limit
    if (currentLength >= maxLength) {
      this.counterTarget.classList.add("text-red-600")
      this.counterTarget.classList.remove("text-yellow-600", "text-gray-500")
    } else if (currentLength >= maxLength * 0.9) {
      this.counterTarget.classList.add("text-yellow-600")
      this.counterTarget.classList.remove("text-gray-500", "text-red-600")
    } else {
      this.counterTarget.classList.add("text-gray-500")
      this.counterTarget.classList.remove("text-yellow-600", "text-red-600")
    }
  }
}