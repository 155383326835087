import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {

  connect() {
    const dropzoneConfig = {
      clickable: true,
      paramName: "import[files]",
      maxFilesize: 256,
      acceptedFiles: ".pdf",
      maxFiles: 20,
      autoProcessQueue: false,
      uploadMultiple: true,
      parallelUploads: 20,
      headers: { "Content-Type": "multipart/form-data" }
    };

    this.dropzone = new Dropzone(this.element, dropzoneConfig);
  }

  upload(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dropzone.processQueue();
  }
}
